<template>
  <div class="w-full grid grid-cols-12 gap-4">
      <div class="col-span-3 flex flex-col">
        <label for="">Ente</label>
        <input type="text"
          :class="inputStyle"
          v-model="form.organization">
        <div class="w-full flex flex-col">
        <!--label for="">
          <input type="checkbox"
            :checked="form.important" 
            :value="form.important" 
            @change="form.important = !form.important"> Gare Urgenti
        </label>
        <label for="">
          <br>
          <input type="checkbox"
            :checked="form.active" 
            :value="form.active" 
            @change="form.active = !form.active"> Attivi
        </label-->
      </div>
      </div>
      <div class="col-span-2 flex flex-col">
        <label for="">Dal</label>
       <input type="date" 
          :class="inputStyle"
          v-model="form.from">
        <label for="">Al</label>
        <input type="date" 
          :class="inputStyle"
          v-model="form.to">
      </div>
      <div class="col-span-2 flex flex-col">
        <label for="">Min €.</label>
        <input type="number"
          :class="inputStyle"
          v-model="form.from_cost">
        <label for="">Max €.</label>
        <input type="number"
          :class="inputStyle"
          v-model="form.to_cost">
      </div>
      <div class="col-span-3">
        <label for="">Procedura</label>
        <select :class="inputStyle" v-model="form.procedure">
            <option
                v-for="(item, index) in procedureList"
                :key="index" 
                :value="item">{{ item }}</option>
        </select>
        <label for="">Modalità Aggiudicazione</label>
        <select :class="inputStyle" v-model="form.award_procedure">
            <option
                v-for="(item, index) in awardProcedureList"
                :key="index" 
                :value="item">{{ item }}</option>
        </select>
        <label for="">Partecipazione</label>
        <select :class="inputStyle" v-model="form.partecipation">
            <option
                v-for="(item, index) in partecipationList"
                :key="index" 
                :value="item">{{ item }}</option>
        </select>
        <label for="">Analisi</label>
        <select :class="inputStyle" v-model="form.analysis">
            <option
                v-for="(item, index) in analysisList"
                :key="index" 
                :value="item">{{ item }}</option>
        </select>
      </div>
      <div class="col-span-2 flex flex-col gap-2">
        <br> <br>
        <button
            class="uppercase py-2 px-4 w-full text-center bg-indigo-600 rounded-md text-white text-xs hover:bg-indigo-500"
              @click="searchAction()">cerca</button>
          <button class="uppercase py-2 w-full text-center bg-primaryRed rounded-md text-white text-xs hover:bg-indigo-500"
              @click="reset()">RESET</button>
      </div>
  </div>
</template>

<script>
export default {
    name: 'TenderSearchFilter',
    data() {
      return {
        inputStyle: "w-full px-2 py-1",
        procedureList: [
            '',
            'APERTA',
            'qualifica invito',
            'manifes. Invito',
            'PRIVATO',
        ],
        partecipationList: [
            '',
            'SINGOLA',
            'ATI CAPOGRUPPO',
            'ATI MANDANTE',
            'non partecipato',
            'consorziata ECL',
        ],
        awardProcedureList: [
            '',
            'MEDIA',
            'MAX RIBASSO',
            'ECON VANTAGGIOSA',
        ],
        analysisList: [
            '',
            'ECL',
            'INTERNA',
            'Massimo',
            'MISTA INTERNA + prog',
            'prog',
            'ALTRI'
        ],
        form: {
          organization: '',
          from: '',
          to: '',
          from_cost: '',
          to_cost: '',
          status: '',
          // important: false,
          // active: true,
          procedure: '',
          partecipation: '',
          award_procedure: '',
        }
      }
    },
    methods: {
      searchAction() {
        this.$emit('searchAction', this.form);
      },
      reset() {
        this.form = {
          organization: '',
          from: '',
          to: '',
          from_cost: '',
          to_cost: '',
          status: '',
          // important: false,
          // active: true,
          procedure: '',
          partecipation: '',
          award_procedure: '',
        }
        this.$emit('reset');
      }
    }
}
</script>

<style>

</style>