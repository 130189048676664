<template>
  <div>
      <div class="w-full flex">
        <h3-title item="Incidenti" />
        <button
          class="absolute right-24 py-2 px-4 text-center bg-indigo-600 rounded-md text-white text-sm hover:bg-indigo-500"
          @click="showNewModal = !showNewModal">
          <i class="fas fa-plus"></i>
        </button>
    </div>

    <table-accidents 
      :itemsHeaderTable="itemsHeaderTable"
      :items="accidents"
      @showDetail="showAccidentDetail"
      @edit="editAccidentForm"
      @delete="deleteAccident" />

    <modal-detail
        v-if="showModalDetail"
        :item="accident"
        @close="showModalDetail = false" />

    <modal-edit-form
        v-if="showEditModal"
        @close="showEditModal = false" 
        title="modifica"
        :item="accident"
        @saveTender="editAccident" />
        
    <modal-new-form
        v-if="showNewModal"
        @close="showNewModal = false" 
        title="aggiungi"
        :item="accident = ''"
        @saveTender="addNewAccident" />

  </div>
</template>

<script>
import AccidentsService from '../services/AccidentsService'
import H3Title from '../components/H3Title'
import Table from '../components/Table'
import ModalEditAccident from '../components/ModalEditAccident'
import ModalDetailAccident from '../components/ModalDetailAccident'


export default {
    name: 'Accidents',
    components: {
      'h3-title' : H3Title,
      'table-accidents' : Table,
      'modal-new-form' : ModalEditAccident,
      'modal-edit-form' : ModalEditAccident,
      'modal-detail' : ModalDetailAccident,
    },
    data() {
        return {
            showModalDetail: false,
            showEditModal: false,
            showNewModal: false,
            accidents: '',
            accident: '',
            itemsHeaderTable: [
                {name: 'cantiere', key: 'construction_site'}, 
                {name: 'Titolo Cantiere', key: 'name'}, 
                {name: 'data', key: 'data'}, 
                {name: 'descrizione', key: 'description'} 
            ],
        }
    },
    mounted() {
        this.getAccidentsList();
    },
    methods: {
        async getAccidentsList() {
            try {
                const response = await AccidentsService.all();
                this.accidents = response.data;
                console.log(this.accidents)
            } catch (error) {
                console.log("error", error)
            }
        },
        showAccidentDetail(value) {
          this.accident = value;
          this.showModalDetail = true;
        },
        editAccidentForm(value) {
          this.accident = value;
          this.showEditModal = true;
        },
        async addNewAccident(value) {
            this.showNewModal = false;
            await AccidentsService.addItem(value);
            this.getAccidentsList();
        },
        async editAccident(value) {
          this.showEditModal = false;
          await AccidentsService.editItem(value.id, value)
          this.getAccidentsList()
        },
        async deleteAccident(value) {
        if(confirm("Do you really want to delete?")) {
          await AccidentsService.deleteItem(value)
          this.getAccidentsList()
        }
      },
    }
}
</script>

<style>

</style>