<template>

    <div class="modal false z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center">
        <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50">
        </div>
        <div class="modal-container bg-white w-11/12 md:w-8/12 mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50" @click="$emit('close')">
                <svg class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" @click="$emit('close')">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                </svg>
            </div>
            <div class="modal-content py-4 text-left px-6">
                <div class="flex justify-between items-center pb-3">
                    <p class="text-2xl font-bold uppercase">{{ title }}</p>
                    <div class="modal-close cursor-pointer z-50">
                        <svg class="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" @click="$emit('close')">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                        </svg>
                    </div>
                </div>


                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 md:col-span-12">
                        <label for="">Titolo:</label>
                        <input type="text"
                            :class="classInput"
                            v-model="form.title">
                    </div>
                    <div class="col-span-12 md:col-span-12">
                        <label for="" :class="classLabel">Data:</label>
                        <input type="date" 
                            :class="classInput"
                            v-model="form.data">
                    </div>
                    <div class="col-span-12 md:col-span-12">
                        <label for="" :class="classLabel">Descrizione</label>
                        <textarea name="" id="" cols="30" rows="5"
                            :class="classInput" v-model="form.description"></textarea>
                    </div>
                    <div class="col-span-12 md:col-span-12">
                        <label for="">
                            <br>
                            <input class="checkbox" type="checkbox" :checked="form.active" :value="form.active" @change="form.active = !form.active"/>
                                Attivo
                        </label>
                    </div>

                    <div class="col col-span-12">
                        <button class="px-6 py-3 bg-indigo-600 rounded-md text-white font-medium tracking-wide hover:bg-indigo-500 w-full"
                            @click="$emit('saveTender', form)">
                            SALVA
                        </button>
                    </div>

                </div>

                <div class="flex justify-end pt-2">
                    <button class="px-6 py-3 bg-transparent p-3 rounded-lg text-indigo-500 hover:bg-gray-100 hover:text-indigo-400 mr-2"
                        @click="$emit('close')">
                        Close 
                    </button>
                    <!--button class="px-6 py-3 bg-indigo-600 rounded-md text-white font-medium tracking-wide hover:bg-indigo-500"> Action </button-->
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { ref } from "vue";

export default {
    name: 'ModalEditNote',
    props: ['item', 'title'],
    data() {
        return {
            classLabel: 'text-xs',
            classInput: 'w-full px-12 py-2 appearance-none rounded-md border border-black focus:border-indigo-600',
            constructions: '',
            form: {
                id: this.item.id || null,
                title: this.item.title,
                data: this.item.data,
                description: this.item.description,
                active: ref(this.item.active),
            },
        }
    },
    mounted() {
    },
    methods: {

    }
}
</script>

<style scoped>

</style>