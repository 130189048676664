<template>

    <div class="modal false z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center">
        <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50">
        </div>
        <div class="modal-container bg-white w-11/12 md:w-8/12 mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50" @click="$emit('close')">
                <svg class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" @click="$emit('close')">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                </svg>
            </div>
            <div class="modal-content py-4 text-left px-6">
                <div class="flex justify-between items-center pb-3">
                    <p class="text-2xl font-bold">{{ item.title }}</p>
                    <div class="modal-close cursor-pointer z-50">
                        <svg class="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" @click="$emit('close')">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                        </svg>
                    </div>
                </div>
                <div class="grid grid-cols-4 gap-4">
                    <div class="col-span-4 md:col-span-12">
                        <p class="text-sm">
                            <strong>
                                Data:
                            </strong>
                            <br>
                            {{ formatData(item.data) }}
                        </p>
                    </div>
                    <div class="col-span-4 md:col-span-12">
                        <p class="text-sm">
                            <strong>
                                Titolo:
                            </strong>
                            <br>
                            {{ item.title }}
                        </p>
                    </div>
                </div>
                <p class="text-sm py-4">
                    <strong>Descrizione:</strong> <br>
                    {{ item.description }}
                </p>
                <div class="flex justify-end pt-2">
                    <button class="px-6 py-3 bg-transparent p-3 rounded-lg text-indigo-500 hover:bg-gray-100 hover:text-indigo-400 mr-2"
                        @click="$emit('close')">
                        Close 
                    </button>
                    <!--button class="px-6 py-3 bg-indigo-600 rounded-md text-white font-medium tracking-wide hover:bg-indigo-500"> Action </button-->
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'ModalDetailNote',
    props: ['item'],
    methods: {
        formatData(tmpData) {
            const x = new Date(tmpData).toLocaleDateString()
            return x
        }
    }
}
</script>

<style scoped>

</style>