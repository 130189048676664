<template>
  <div>
    <div class="w-full flex">
        <h3-title item="Gare" />
        <button
          class="absolute right-52 py-2 px-4 text-center bg-primaryRed rounded-md text-white text-sm hover:bg-indigo-500"
          @click="getTendersList()">
          <i class="fas fa-sync-alt"></i>
        </button>
        <button
          class="absolute right-36 py-2 px-4 text-center bg-indigo-600 rounded-md text-white text-sm hover:bg-indigo-500"
          @click="showSearch = !showSearch">
          <i class="fas fa-search"></i>
        </button>
        <button
          class="absolute right-24 py-2 px-4 text-center bg-indigo-600 rounded-md text-white text-sm hover:bg-indigo-500"
          @click="showNewModal = !showNewModal">
          <i class="fas fa-plus"></i>
        </button>
    </div>

    <tender-search-filter
      v-if="showSearch"
      @searchAction="filterTenders"
      @reset="getTendersList()"></tender-search-filter>
    

    <table-tenders 
      :itemsHeaderTable="itemsHeaderTable"
      :items="tenders"
      @duplicate="duplicateTender"
      @showDetail="showTenderDetail"
      @edit="editTenderForm"
      @delete="deleteTender" />
      
      <modal-detail
        v-if="showModalDetail"
        :item="tender"
        @close="showModalDetail = false" />

      <modal-edit-form
        v-if="showEditModal"
        @close="showEditModal = false" 
        title="modifica"
        :tender="tender"
        @saveTender="editTender" />

      <modal-new-form
        v-if="showNewModal"
        @close="showNewModal = false" 
        title="aggiungi"
        :tender="tender = ''"
        :number="lastNumber"
        @saveTender="addNewTender" />

  </div>
</template>

<script>
import TendersService from '../services/TendersService'
import H3Title from '../components/H3Title'
import Table from '../components/Table'
import ModalDetailTender from '../components/ModalDetailTender'
import ModalEditTender from '../components/ModalEditTender'
import TenderSearchFilter from '../components/TenderSearchFilter'

export default {
    name: 'Tenders',
    components: {
      'table-tenders' : Table,
      'h3-title' : H3Title,
      'modal-detail' : ModalDetailTender,
      'modal-edit-form' : ModalEditTender,
      'modal-new-form' : ModalEditTender,
      'tender-search-filter' : TenderSearchFilter
    },
    data() {
      return {
        itemsHeaderTable: [
                {name: 'Nr', key: 'number'}, 
                {name: 'Ente', key: 'organization'}, 
                {name: 'DataScadenza', key: 'expiry'}, 
                {name: 'Oggetto', key: 'description'}, 
                {name: 'Importo', key: 'amount'}, 
                {name: 'Procedura', key: 'procedure'}, 
                {name: 'Partecipazione', key: 'partecipation'}, 
                {name: 'Modalità Aggiudicazione', key: 'award_procedure'},
                {name: 'Analisi', key: 'analysis'},
                {name: 'Costo', key: 'cost'},
                //{name: 'Note', key: 'note'}
        ],
        tenders: '',
        tender: '',
        showModalDetail: false,
        showEditModal: false,
        showNewModal: false,
        lastNumber: 0,
        showSearch: false
      }
    },
    mounted() {
      this.getTendersList();
    },
    methods: {
      async getTendersList() {
        this.showSearch = false;
        try {
          const response = await TendersService.all();
          this.tenders = response.data;
          this.lastNumber = response.data[0].number + 1
          console.log(this.tenders)
        } catch (error) {
          console.log("error", error)
        }
      },
      showTenderDetail(value) {
        this.tender = value;
        this.showModalDetail = true;
      },
      editTenderForm(value) {
        this.tender = value;
        this.showEditModal = true;
      },
      async editTender(value) {
        this.showEditModal = false;
        await TendersService.editItem(value.id, value)
        this.getTendersList()
      },
      async addNewTender(value) {
        this.showNewModal = false;
        await TendersService.addItem(value);
        this.getTendersList();
      },
      async duplicateTender(value) {
        try {
          value.number = this.lastNumber;
          await TendersService.addItem(value);
          this.getTendersList();
          alert("Gara Duplicata con Successo");  
        } catch (error) {
          alert("Duplicazione Gara Fallita!");  
        }
      },
      async deleteTender(value) {
        if(confirm("Do you really want to delete?")) {
          await TendersService.deleteItem(value)
          this.getTendersList()
        }
      },
      async filterTenders(value) {
        this.showSearch = false;
        console.log("filter tenders", value.organization);
        try {
          const response = await TendersService.getFilter(value)
          this.tenders = response.data;
          console.log(this.tenders)
        } catch (error) {
          console.log("error", error)
        }
      }
    }
}
</script>

<style>

</style>