<template>

    <div class="modal false z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center">
        <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50">
        </div>
        <div class="modal-container bg-white w-11/12 md:w-8/12 mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50" @click="$emit('close')">
                <svg class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" @click="$emit('close')">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                </svg>
            </div>
            <div class="modal-content py-4 text-left px-6">
                <div class="flex justify-between items-center pb-3">
                    <p class="text-2xl font-bold uppercase">{{ title }}</p>
                    <div class="modal-close cursor-pointer z-50">
                        <svg class="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" @click="$emit('close')">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                        </svg>
                    </div>
                </div>

                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 md:col-span-3">
                        <label for="" :class="classLabel">Data:</label>
                        <input type="date" 
                            :class="classInput"
                            v-model="form.expiry">
                    </div>
                    <div class="col-span-12 md:col-span-2">
                        <label for="" :class="classLabel">Numero:</label>
                        <input type="number"
                            :class="classInput"
                            v-model="form.number">
                    </div>
                    <div class="col-span-12 md:col-span-7">
                        <label for="" :class="classLabel">Ente</label>
                        <!--input type="text"
                            :class="classInput"
                            v-bind:value="form.organization"
                            v-on:input="form.organization = $event.target.value"
                        -->
                        <input type="text"
                            :class="classInput"
                            v-model="form.organization" />
                    </div>

                    <div class="col-span-12 md:col-span-12">
                        <label for="" :class="classLabel">Descrizione</label>
                        <textarea name="" id="" cols="30" rows="5"
                            :class="classInput" v-model="form.description"></textarea>
                    </div>

                    <div class="col-span-12"><hr></div>
                    <!--div class="col-span-12 md:col-span-4">
                        <label for="">Indirizzo</label>
                         <input type="text"
                            :class="classInput"
                            v-model="form.address" />
                    </div>
                    <div class="col-span-12 md:col-span-2">
                        <label for="">Cap</label>
                         <input type="text"
                            :class="classInput"
                            v-model="form.zip" />
                    </div>
                    <div class="col-span-12 md:col-span-3">
                        <label for="">Città</label>
                         <input type="text"
                            :class="classInput"
                            v-model="form.city" />
                    </div>
                    <div class="col-span-12 md:col-span-1">
                        <label for="">Provincia</label>
                         <input type="text"
                            :class="classInput"
                            v-model="form.district" />
                    </div>
                    <div class="col-span-12 md:col-span-2">
                        <label for="">Nazione</label>
                         <input type="text"
                            :class="classInput"
                            v-model="form.country" />
                    </div-->
                    <div class="col-span-12"><hr></div>

                    <div class="col-span-12 md:col-span-4">
                        <label for="" :class="classLabel">Importo</label>
                        <input type="text"
                            :class="classInput"
                            v-model="form.amount">
                    </div>
                    <div class="col-span-12 md:col-span-4">
                        <label for="" :class="classLabel">Procedura</label>
                        <select :class="classInput" v-model="form.procedure">
                            <option
                                v-for="(item, index) in procedureList"
                                :key="index" 
                                :value="item">{{ item }}</option>
                        </select>
                    </div>
                    <div class="col-span-12 md:col-span-4">
                        <label for="" :class="classLabel">Partecipazione</label>
                        <select :class="classInput" v-model="form.partecipation">
                            <option
                                v-for="(item, index) in partecipationList"
                                :key="index" 
                                :value="item">{{ item }}</option>
                        </select>
                    </div>

                    <div class="col-span-12 md:col-span-4">
                        <label for="" :class="classLabel">Modalità Aggiudicazione</label>
                        <select :class="classInput" v-model="form.award_procedure">
                            <option
                                v-for="(item, index) in awardProcedureList"
                                :key="index" 
                                :value="item">{{ item }}</option>
                        </select>
                    </div>
                    <div class="col-span-12 md:col-span-4">
                        <label for="" :class="classLabel">Analisi</label>
                        <select :class="classInput" v-model="form.analysis">
                            <option
                                v-for="(item, index) in analysisList"
                                :key="index" 
                                :value="item">{{ item }}</option>
                        </select>
                    </div>
                    <div class="col-span-12 md:col-span-4">
                        <label for="" :class="classLabel">Costo</label>
                        <input type="text"
                            :class="classInput"
                            v-model="form.cost">
                    </div>

                    <div class="col-span-12 md:col-span-12">
                        <label for="" :class="classLabel">Note</label>
                        <textarea name="" id="" cols="30" rows="5"
                            :class="classInput" v-model="form.note"></textarea>
                    </div>

                    <div class="col-span-12 md:col-span-4">
                        <label for="">Status:</label>
                        <input type="text"
                            :class="classInput"
                            v-model="form.status">
                    </div>
                    <div class="col-span-12 md:col-span-4">
                        <label for="">
                            <br>
                            <input class="checkbox" type="checkbox" :checked="form.important" :value="form.important" @change="form.important = !form.important"/>
                                Gare Urgenti
                        </label>
                    </div>
                    <!--div class="col-span-12 md:col-span-4">
                        <label for="">
                            <br>
                            <input class="checkbox" type="checkbox" :checked="form.active" :value="form.active" @change="form.active = !form.active"/>
                                Attivo
                        </label>
                    </div-->

                    <div class="col col-span-12">
                        <button class="px-6 py-3 bg-indigo-600 rounded-md text-white font-medium tracking-wide hover:bg-indigo-500 w-full"
                            @click="$emit('saveTender', form)">
                            SALVA
                        </button>
                    </div>

                </div>

                <div class="flex justify-end pt-2">
                    <button class="px-6 py-3 bg-transparent p-3 rounded-lg text-indigo-500 hover:bg-gray-100 hover:text-indigo-400 mr-2"
                        @click="$emit('close')">
                        Close 
                    </button>
                    <!--button class="px-6 py-3 bg-indigo-600 rounded-md text-white font-medium tracking-wide hover:bg-indigo-500"> Action </button-->
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { ref } from "vue";

export default {
    name: 'ModalEditTender',
    props: ['tender', 'title', 'number'],
    data() {
        return {
            classLabel: 'text-xs',
            classInput: 'w-full px-12 py-2 appearance-none rounded-md border border-black focus:border-indigo-600',
            form: {
                id: this.tender.id || null,
                expiry: this.tender.expiry,
                number: this.tender.number,
                organization: this.tender.organization,
                description: this.tender.description,
                amount: this.tender.amount,
                procedure: this.tender.procedure,
                partecipation: this.tender.partecipation || '',
                award_procedure: this.tender.award_procedure,
                analysis: this.tender.analysis,
                cost: this.tender.cost,
                note: this.tender.note,
                address: this.tender.address,
                zip: this.tender.zip,
                city: this.tender.city,
                district: this.tender.district,
                country: this.tender.country,
                status: this.tender.status,
                active:  ref(this.tender.active),
                important: ref(this.tender.important)
            },
            procedureList: [
                '',
                'APERTA',
                'qualifica invito',
                'manifes. Invito',
                'PRIVATO',
            ],
            partecipationList: [
                '',
                'SINGOLA',
                'ATI CAPOGRUPPO',
                'ATI MANDANTE',
                'non partecipato',
                'consorziata ECL',
            ],
            awardProcedureList: [
                '',
                'MEDIA',
                'MAX RIBASSO',
                'ECON VANTAGGIOSA',
            ],
            analysisList: [
                '',
                'ECL',
                'INTERNA',
                'Massimo',
                'MISTA INTERNA + prog',
                'prog',
                'ALTRI'
            ]
        }
    },
    mounted() {
        if (this.number) {
            this.form.number = this.number;
        }
    },
}
</script>

<style scoped>

</style>