
<template>
  <div class="flex">
    <!-- Backdrop -->
    <div
      :class="isOpen ? 'block' : 'hidden'"
      @click="isOpen = false"
      class="fixed z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"
    ></div>
    <!-- End Backdrop -->

    <div
      :class="isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'"
      class="fixed z-30 inset-y-0 left-0 w-42 transition duration-300 transform bg-primaryGrey overflow-y-auto lg:translate-x-0 lg:static lg:inset-0"
    >
      <div class="flex items-center justify-center mt-8">
        <div class="flex items-center">
          <!--img src="@/assets/logo.jpg" alt=""-->
        </div>
      </div>

      <nav class="mt-10">
        <router-link
            v-for="(item, index) in menuItems" :key="index" 
            class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
            :class="[$route.name === 'Dashboard' ? activeClass : inactiveClass]"
            :to="item.to"
        >
            <i :class="item.icon"></i>
            <span class="mx-4">{{ item.name }}</span>
        </router-link>

        <button v-if="isLoggedIn"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[$route.name === 'Dashboard' ? activeClass : inactiveClass]"
          @click="logout()">
          <i class="fas fa-sign-out-alt"></i>
            &nbsp;&nbsp;Logout
        </button>
        <router-link v-if="!isLoggedIn"
            class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
            :class="[$route.name === 'Dashboard' ? activeClass : inactiveClass]"
            to="/login"
        >
            <i class="fas fa-sign-in-alt"></i>
            <span class="mx-4"> Login</span>
        </router-link>

      </nav>
    </div>
  </div>
</template>

<script>

export default {
    name: 'Sidebar',
    props: ['menuItems'],
    data() {
        return {
            activeClass: 'bg-gray-600 bg-opacity-25 text-white border-gray-100',
            inactiveClass: 'border-gray-900 text-white hover:bg-gray-600 hover:bg-opacity-25 hover:text-gray-100',
            isOpen: false,
        }
    },
    methods: {
      logout() {
        this.$store.replaceState({})
        localStorage.clear();
        this.$router.push('/login');
      }
    },
    computed: {
      isLoggedIn() {
        return this.$store.state.isLoggedIn
      }
    }
}

</script>