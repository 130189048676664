<template>
  <h3 class="text-gray-700 text-3xl font-medium">{{ item }}</h3>
</template>

<script>
export default {
    name: 'H3Title',
    props: ['item']
}
</script>

<style scoped>

</style>