import Api from './Api'

export default {

    all() {
        return Api().get('/tenders/all')
            .catch(error => { console.log(error.response) })
    },

    getFilter(value) {
        return Api().get('/tenders/all', {
            params: {
                organization: value.organization,
                // active: value.active,
                // important: value.important,
                // status: value.status,
                from: value.from,
                to: value.to,
                from_cost: value.from_cost,
                to_cost: value.to_cost,
                procedure: value.procedure,
                partecipation: value.partecipation,
                award_procedure: value.award_procedure
            }
        })
        .catch(error => { console.log(error.response) })
    },

    deleteItem(id) {
        return Api().delete(`/tenders/${id}`)
                .catch(error => { console.log(error) })
    },

    addItem(value) {
        return Api().post(`/tenders`, value)
                .catch(error => { console.log(error) })
    },

    editItem(id, value) {
        return Api().put(`/tenders/${id}`, value)
                .catch(error => { console.log(error) })
    }

}
