<template>
  <header
    class="flex justify-between items-center py-4 px-6 bg-white border-b-4 border-primaryRed"
  >
    <div class="flex items-center">
      <button
        @click="isOpen = true"
        class="text-gray-500 focus:outline-none lg:hidden"
      >
        <svg
          class="h-6 w-6"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6H20M4 12H20M4 18H11"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <div class="relative mx-4 lg:mx-0">
        <span class="absolute inset-y-0 left-0 pl-3 flex items-center"
          @click="$emit('showHideSidebar')">
          <i class="fas fa-bars text-3xl"></i>
        </span>
      </div>
    </div>

    <div class="flex items-center">
      <span class="text-2xl text-center font-bold">
        <img src="@/assets/logo.jpg" alt="">
      </span>

      <div class="relative">

      </div>
    </div>
  </header>
</template>

<script>

</script>