import { createApp } from 'vue'
import {store} from './store'


import App from './App.vue'
import '@fortawesome/fontawesome-free/css/all.css'
import './assets/styles/tailwind.css';
import {axios} from 'axios'
import router from './routes'
/*
createApp(App).use(
                router,
                store,
                axios
                ).mount('#app')

*/

const app = createApp(App);
app.use(router);
app.use(store);
app.use(axios);
app.mount('#app');
