import Api from './Api'

export default {

    all() {
        return Api().get('/accidents')
            .catch(error => { console.log(error.response) })
    },

    /*
    getFilter(value) {
        return Api().get('/tenders', {
            params: {
                organization: value.organization,
                active: value.active,
                important: value.important,
                status: value.status,
                from: value.from,
                to: value.to
            }
        })
        .catch(error => { console.log(error.response) })
    },
    */

    deleteItem(id) {
        return Api().delete(`/accidents/${id}`)
                .catch(error => { console.log(error) })
    },

    addItem(value) {
        return Api().post(`/accidents`, value)
                .catch(error => { console.log(error) })
    },

    editItem(id, value) {
        return Api().put(`/accidents/${id}`, value)
                .catch(error => { console.log(error) })
    }

}