<template>
  <div>HOME</div>
</template>

<script>
export default {
    name: 'Home',
    data() {
      return {
        baseUrl: process.env.VUE_APP_ROOT_API
      }
    },
    mounted() {
      console.log("env file", this.baseUrl)
    }
}
</script>

<style>

</style>