<template>
   <div class="bg-white shadow rounded-md overflow-auto my-6">
        <table class="text-left w-full border-collapse">
            <thead class="border-b">
                <tr>
                    <th class="py-3 px-5 bg-primaryGrey font-medium uppercase text-xs text-gray-100"
                        v-for="(item, index) in itemsHeaderTable" :key="index">
                        {{ item.name }}
                    </th>
                    <th class="py-3 px-5 bg-primaryGrey font-medium uppercase text-xs text-gray-100 w-64">
                        Vedi - Modifica - Cancella
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(item, index) in items"
                    :key="index"
                    class="hover:bg-gray-200"
                >
                    <td class="py-4 px-6 border-b text-gray-700 text-xs"
                        v-for="(key, index) in itemsHeaderTable" :key="index">
                        <span v-if="key.key == 'amount'">
                             {{ formatPrice(item[key.key]) }}
                        </span>
                         <span v-else-if="key.key == 'expiry'">
                             {{ formatExpiryDate(item[key.key]) }}
                        </span>
                        <span v-else-if="key.key == 'start'">
                             {{ formatExpiryDate(item[key.key]) }}
                        </span>
                        <span v-else-if="key.key == 'end'">
                             {{ formatExpiryDate(item[key.key]) }}
                        </span>
                        <span v-else-if="key.key == 'data'">
                             {{ formatExpiryDate(item[key.key]) }}
                        </span>
                        <span v-else>
                            {{ item[key.key] }}
                        </span>
                    </td>
                    <td class="py-4 px-6 border-b text-gray-700 text-xs w-64">
                        <div class="flex gap-2">
                            <button class="px-3 py-1 bg-indigo-600 text-white rounded-md text-sm hover:bg-indigo-500 focus:outline-none"
                                @click="$emit('duplicate', item)">
                                <i class="fas fa-copy"></i>
                            </button>
                            <button class="px-3 py-1 bg-indigo-600 text-white rounded-md text-sm hover:bg-indigo-500 focus:outline-none"
                                @click="$emit('showDetail', item)">
                                <i class="fas fa-eye"></i>
                            </button>
                            <button class="px-3 py-1 bg-yellow-500 text-white rounded-md text-sm hover:bg-yellow-700 focus:outline-none"
                                @click="$emit('edit', item)">
                                <i class="fas fa-edit"></i>
                            </button>
                            <button class="px-3 py-1 bg-red-500 text-white rounded-md text-sm hover:bg-red-700 focus:outline-none"
                                @click="$emit('delete', item.id)">
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
   </div>
</template>

<script>
import currency from 'currency.js'

export default {
    name: 'Table',
    props: ['items', 'itemsHeaderTable'],
    methods: {
        formatPrice(value) {
            return currency(value, {
                symbol: '€', decimal: '.', separator: ','
            }).format()
        },
        formatExpiryDate(value) {
            var data = new Date(value)
            return data.toLocaleDateString() 
        }
    }
}
</script>

<style>

</style>