
import { createStore } from 'vuex'

export const store = createStore({
    state() {
        return {
            token: localStorage.getItem('token'),
            userInfo: localStorage.getItem('userInfo'),
            isLoggedIn: localStorage.getItem('isLoggedIn'),
            isAdmin: localStorage.getItem('isAdmin'),
        }
    },
    mutations: {
        setToken(state, token) {
            state.token = token
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        setIsLoggedIn(state) {
            state.isLoggedIn = true
        },
        setIsAdmin(state, role) {
            if (role == 'admin') {
                state.isAdmin = true
            }
        }
    }
})

//export default store;

                
