import { createWebHistory, createRouter } from "vue-router";
import {store} from './store'
import Login from './pages/Login'
import Home from './pages/Home'
import Tenders from './pages/Tenders'
import Constructions from './pages/Constructions'
import Accidents from './pages/Accidents'
import Notes from './pages/Notes'

const routes = [
    { 
      path: '/', 
      name: 'Home', 
      component: Home,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'Login', 
      component: Login
    },
    {
      path: '/gare',
      name: 'Tenders',
      component: Tenders,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/cantieri',
      name: 'Constructions',
      component: Constructions,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/incidenti',
      name: 'Accidents',
      component: Accidents,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/note',
      name: 'Notes',
      component: Notes,
      meta: {
        requiresAuth: true
      }
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router;