<template>
    <div class="flex justify-center h-auto bg-gray-200 px-6 mt-8">
        <div class="p-6 max-w-sm w-full bg-white shadow-md rounded-md">
            <div class="flex justify-center items-center">
                <img src="@/assets/logo.jpg" alt="logo" class="mx-auto w-32 pb-4">
            </div>
            <div class="w-full text-center">
                <span class="text-gray-700 font-semibold text-2xl text-center">AREA RISERVATA</span>
            </div>
            <form class="mt-4" @submit.prevent="login()">
                <label class="block">
                    <span class="text-gray-700 text-sm">Username</span>
                    <input type="text" class="mt-1 block w-full rounded-md focus:border-indigo-600"
                        v-model="username">
                </label>
                <label class="block mt-3">
                    <span class="text-gray-700 text-sm">Password</span>
                    <input type="password" class="mt-1 block w-full rounded-md focus:border-indigo-600"
                        v-model="password">
                </label>
                <div class="flex justify-between items-center mt-4">
                    <div>
                        <label class="inline-flex items-center">
                            <input type="checkbox" class="form-checkbox text-indigo-600">
                            <span class="mx-2 text-gray-600 text-sm">Remember me</span>
                        </label>
                    </div>
                    <div>
                        <a class="block text-sm fontme text-indigo-700 hover:underline" href="#">Forgot your password?</a>
                    </div>
                </div>
                <div class="mt-6">
                    <button type="submit" class="py-2 px-4 text-center bg-primaryGrey rounded-md w-full text-white text-sm hover:bg-indigo-500">
                        ENTRA
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Login',
    data() {
        return {
            username: '',
            password: '',
        }
    },
    methods: {
        async login() {
            console.log('login', this.username);
            try {
                const response = await axios.post(`${process.env.VUE_APP_ROOT_API}/login`, {
                    username: this.username,
                    password: this.password
                });
                console.log("response", response.data);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('userInfo', response.data.user);
                localStorage.setItem('isLoggedIn', true);
                if (response.data.user.role == 'admin') {
                    localStorage.setItem('isAdmin', true);
                } else {
                    localStorage.setItem('isAdmin', false);
                }

                this.$store.commit('setToken', response.data.token);
                this.$store.commit('setUserInfo', response.data.user);
                this.$store.commit('setIsLoggedIn', true);
                this.$store.commit('setIsAdmin', response.data.user.role);

                this.$router.push('/gare')
                
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>

<style>

</style>