<template>

<div class="flex h-screen bg-gray-200 font-roboto"> 

  <sidebar-left :menuItems="menuItems" v-if="showSidebar"/>
 
  <div class="flex-1 flex flex-col overflow-hidden">
    
    <header-top 
      @showHideSidebar="showSidebar= !showSidebar"/>

    <main class="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
        <div class="w-full mx-auto px-6 py-8">
            <router-view />
        </div>
    </main>

  </div>
</div>

</template>

<script>

import Header from './components/Header'
import Sidebar from "./components/Sidebar.vue";

export default {
  name: 'App',
  components: {
    'header-top' : Header,
    'sidebar-left' : Sidebar
  },
  data() {
    return {
      showSidebar: false,
      menuItems: [
        {
          name: 'Dashboard',
          icon: 'fas fa-chart-pie',
          to: '/'
        },
        {
          name: 'Gare',
          icon: 'far fa-list-alt',
          to: '/gare'
        },
        {
          name: 'Cantieri',
          icon: 'far fa-list-alt',
          to: '/cantieri'
        },
        {
          name: 'Incidenti',
          icon: 'far fa-list-alt',
          to: '/incidenti'
        },
        {
          name: 'Note',
          icon: 'far fa-list-alt',
          to: '/note'
        }
      ]
    }
  },
}
</script>

<style>
#app {

}
</style>
