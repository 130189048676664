<template>
  <div>

    <div class="w-full flex">
        <h3-title item="Note" />
        <button
          class="absolute right-24 py-2 px-4 text-center bg-indigo-600 rounded-md text-white text-sm hover:bg-indigo-500"
          @click="showNewModal = !showNewModal">
          <i class="fas fa-plus"></i>
        </button>
    </div>

    <table-notes 
      :itemsHeaderTable="itemsHeaderTable"
      :items="notes"
      @showDetail="showNoteDetail"
      @edit="editNoteForm"
      @delete="deleteNote" />

    <modal-detail
        v-if="showModalDetail"
        :item="note"
        @close="showModalDetail = false" />
    
    <modal-edit-form
        v-if="showEditModal"
        @close="showEditModal = false" 
        title="modifica"
        :item="note"
        @saveTender="editNote" />

    <modal-new-form
        v-if="showNewModal"
        @close="showNewModal = false" 
        title="aggiungi"
        :item="note = ''"
        @saveTender="addNewNote" />


  </div>
</template>

<script>
import NotesService from '../services/NotesService'
import H3Title from '../components/H3Title'
import Table from '../components/Table'
import ModalEditNote from '../components/ModalEditNote'
import ModalDetailNote from '../components/ModalDetailNote'

export default {
    name: 'Notes',
    components: {
        'h3-title' : H3Title,
        'table-notes' : Table,
        'modal-edit-form' : ModalEditNote,
        'modal-new-form' : ModalEditNote,
        'modal-detail' : ModalDetailNote,
    },
    data() {
        return {
            showModalDetail: false,
            showEditModal: false,
            showNewModal: false,
            notes: '',
            note: '',
            itemsHeaderTable: [
                {name: 'titolo', key: 'title'}, 
                {name: 'Data', key: 'data'}, 
                {name: 'Descrizione', key: 'description'}
            ],
        }
    },
    mounted() {
        this.getNotesList();
    },
    methods: {
        async getNotesList() {
            try {
                const response = await NotesService.all();
                this.notes = response.data;
                console.log(this.accidents)
            } catch (error) {
                console.log("error", error)
            }
        },
        showNoteDetail(value) {
            this.note = value;
            this.showModalDetail = true;
        },
        editNoteForm(value) {
            this.note = value;
            this.showEditModal = true;
        },
        async addNewNote(value) {
            this.showNewModal = false;
            await NotesService.addItem(value);
            this.getNotesList();
        },
        async editNote(value) {
            this.showEditModal = false;
            await NotesService.editItem(value.id, value)
            this.getNotesList()
        },
        async deleteNote(value) {
            if(confirm("Do you really want to delete?")) {
                await NotesService.deleteItem(value)
                this.getNotesList()
            }
        }
    }
}
</script>

<style>

</style>