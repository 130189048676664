
import {store} from '../store'
import axios from 'axios'

export default () => {
  return axios.create({
    baseURL: `${process.env.VUE_APP_ROOT_API}`,
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  })
}
