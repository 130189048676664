<template>
    <div>
        <div class="w-full flex gap-8">
            <div class="flex-1">
                <h3-title item="Cantieri" />
            </div>
            <div class="relative flex gap-8">
                <a href="https://suardiapi.glocalnow.app/document/excell.xlsx" target="_blank" class="py-2 px-4 text-center bg-indigo-600 rounded-md text-white text-sm hover:bg-indigo-500">
                    <i class="fas fa-file-excel"></i>&nbsp;&nbsp;scarica file excell
                </a>
                <button
                    class="py-2 px-4 text-center bg-indigo-600 rounded-md text-white text-sm hover:bg-indigo-500"
                    @click="showNewModal = !showNewModal">
                    <i class="fas fa-plus"></i>
                </button>
            </div>
        </div>

        <table-tenders 
            :itemsHeaderTable="itemsHeaderTable"
            :items="constructions"
            @showDetail="showConstructionDetail"
            @edit="editConstructionForm"
            @delete="deleteConstruction" />

        <modal-detail
            v-if="showModalDetail"
            :item="construction"
            @close="showModalDetail = false" />


        <modal-edit-form
            v-if="showEditModal"
            @close="showEditModal = false" 
            title="modifica"
            :item="construction"
            @saveTender="editConstruction" />

        <modal-new-form
            v-if="showNewModal"
            @close="showNewModal = false" 
            title="aggiungi"
            :item="construction = ''"
            @saveTender="addNewConstruction" />
  </div>
</template>

<script>
import ConstructionsService from '../services/ConstructionsService'
import Table from '../components/Table'
import ModalEditConstruction from '../components/ModalEditConstruction'
import ModalDetailConstruction from '../components/ModalDetailConstruction'
import H3Title from '../components/H3Title'

export default {
    name: 'Tenders',
    components: {
        'table-tenders' : Table,
        'h3-title' : H3Title,
        'modal-new-form' : ModalEditConstruction,
        'modal-edit-form' : ModalEditConstruction,
        'modal-detail' : ModalDetailConstruction,
    },
    data() {
        return {
            showNewModal: false,
            showEditModal: false,
            showModalDetail: false,
            constructions: '',
            construction: '',
            itemsHeaderTable: [
                {name: 'code', key: 'code'}, 
                {name: 'tipologia', key: 'typology'},
                {name: 'Cantiere', key: 'name'}, 
                {name: 'Inizio', key: 'start_date'}, 
                {name: 'Fine', key: 'end_date'}, 
                {name: 'durata', key: 'duration'},
                {name: 'attivo', key: 'active'}
            ],
        }
    },
    mounted() {
        this.getConstructions();
    },
    methods: {
        async getConstructions() {
            try {
                const response = await ConstructionsService.all();
                this.constructions = response.data;
            } catch (error) {
                console.log("error", error);
            }
        },
        showConstructionDetail(value) {
            this.construction = value;
            this.showModalDetail = true;
        },
        editConstructionForm(value) {
            this.construction = value;
            this.showEditModal = true;
        },
        async editConstruction(value) {
            this.showEditModal = false;
            await ConstructionsService.editItem(value.id, value)
            this.getConstructions()
        },
        async addNewConstruction(value) {
            this.showNewModal = false;
            await ConstructionsService.addItem(value);
            this.getConstructions();
        },
        async deleteConstruction(value) {
            if(confirm("Do you really want to delete?")) {
                await ConstructionsService.deleteItem(value)
                this.getConstructions()
            }
        },
    }
}
</script>

<style>

</style>